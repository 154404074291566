import { useState } from "react"

type ReturnedSig = {
  start: () => Promise<void>
  stop: () => void
  mimeType: string
  isRecording: boolean
}

export const useVoiceRecorder = (cb: (result: Blob) => void): ReturnedSig => {
  const [recorder, setRecorder] = useState<MediaRecorder>()
  const [mimeType, setMimeType] = useState<string>( "" )

  const finishRecording = ( { data }: { data: Blob } ) => { cb( data ) }

  const start = async () => {
    if ( recorder?.state === "recording" ) return

    if ( MediaRecorder.isTypeSupported('audio/webm') ) {
      setMimeType( "audio/webm" )
    } else if ( MediaRecorder.isTypeSupported('audio/mp4') ) {
      setMimeType( "audio/mp4" )
    } else {
      alert( "Mime types not supported." )
      return
    }

    const stream = await navigator.mediaDevices.getUserMedia( { audio: true } )
    const mediaRecorder = new MediaRecorder( stream, { mimeType } )

    await mediaRecorder.start()
    setRecorder( mediaRecorder )

    mediaRecorder.addEventListener('dataavailable', finishRecording)
  }

  const stop = async () => {
    if ( recorder ) {
      await recorder.stop()
      await recorder.stream.getTracks().forEach( track => track.stop() )

      recorder.removeEventListener('dataavailable', finishRecording)
    }
  }

  return {
    start,
    stop,
    mimeType,
    isRecording: recorder?.state === "recording",
  }
}
