import React, { useCallback, useEffect, useState } from 'react'
import { useVoiceRecorder } from "./use-voice-recorder"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faMicrophone, faSpinner } from '@fortawesome/free-solid-svg-icons'

type ReturnedResult = {
  completed: boolean
  result: {
    items: {
      start_time: string
      end_time: string
      type: "pronunciation" | "punctuation"
      alternatives: {
        confidence: string
        content: string
      }[]
    }[]
    transcripts: {
      transcript: string
    }[]
  }
}

type Result = {
  startTime: number
  endTime: number
  type: "pronunciation" | "punctuation"
  content: string
  isHighlighted: boolean
}[]

const App = () => {
  const [ language, setLanguage ] = useState<string>("en-US")
  const [ password, setPassword ] = useState<string>("")
  const [ audioBlob, setAudioBlob ] = useState<Blob>()
  const [ audioObject, setAudioObject ] = useState<HTMLAudioElement>()
  const [ audioPlaying, setAudioPlaying ] = useState<boolean>(false)
  const [ result, setResult ] = useState<Result>()
  const [ isWaiting, setIsWaiting ] = useState<boolean>(false)
  const [ intervalId, setIntervalId ] = useState<number>(0)

  const { isRecording, mimeType, stop, start } = useVoiceRecorder( ( data ) => {
    const audio = new Audio( window.URL.createObjectURL( data ) )

    audio.onended = () => {
      setAudioPlaying( false )
      setResult( oldResult => oldResult?.map( item => ( { ...item, isHighlighted: false } ) ) )
    }

    audio.onplaying = () => {
      setAudioPlaying( true )
    }

    audio.onpause = () => {
      setAudioPlaying( false )
    }

    setAudioBlob( data )
    setAudioObject( audio )
  } )

  const updateHighlights = useCallback( () => {
    const currentTime = audioObject?.currentTime

    if ( !currentTime ) {
      return
    }

    setResult( oldResult => oldResult?.map( item => {
      return {
        ...item,
        isHighlighted: item.startTime < currentTime && item.endTime > currentTime
      }
    } ) )
  }, [audioObject?.currentTime] )

  useEffect( () => {
    setPassword( localStorage.getItem( 'password' ) ?? "" )
  }, [] )

  useEffect( () => {
    if ( audioPlaying && intervalId === 0 ) {
      const newIntervalId = window.setInterval( updateHighlights, 1 )
      setIntervalId( newIntervalId )
    }

    if ( !audioPlaying && intervalId !== 0 ) {
      clearInterval( intervalId )
      setIntervalId( 0 )
    }
  }, [audioPlaying, intervalId, updateHighlights] )

  const getTranscription = async ( jobUuid: string ) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/getTranscription/${jobUuid}`, { method: "GET" } )
    const result = await response.json() as ReturnedResult

    if ( result.completed ) {
      setResult( result.result.items.map( item => ({
        startTime: parseFloat( item.start_time ),
        endTime: parseFloat( item.end_time ),
        type: item.type,
        content: item.alternatives[0].content,
        isHighlighted: false,
      }) ) )
    } else {
      await getTranscription( jobUuid )
    }
  }

  const submit = async ( e: React.FormEvent<HTMLFormElement> ) => {
    e.preventDefault()

    if ( !audioBlob ) {
      return
    }

    setIsWaiting( true )

    const formData = new FormData()
    formData.append( "file", audioBlob )
    formData.append( "language", language )
    formData.append( "password", password )
    formData.append( "mimeType", mimeType )

    const response = await fetch(`${process.env.REACT_APP_API_URL}/transcribe`, { method: "POST", body: formData } )

    if (response.status >= 400 && response.status < 600) {
      setIsWaiting( false )

      if ( response.status === 401 ) {
        alert( "Invalid password!" )
      } else {
        alert("Something went wrong!")
      }

      return
    }

    localStorage.setItem( 'password', password )

    const result = await response.json()
    const jobUuid = result.jobUuid
    await getTranscription( jobUuid )
  }

  if ( !audioBlob || !audioObject ) {
    return <div className="flex h-full text-sky-600" style={{ fontSize: "200px", }}>
      <div className="m-auto">
        {
          isRecording ?
            <button onClick={stop}><FontAwesomeIcon beatFade={true} icon={faMicrophone} /></button> :
            <button onClick={start}><FontAwesomeIcon icon={faMicrophone} /></button>
        }
      </div>
    </div>
  }

  if ( !result ) {
    return <>
      {
        isWaiting ?
          <div className="flex h-full text-sky-600" style={{ fontSize: "200px", }}>
            <div className="m-auto"><FontAwesomeIcon icon={faSpinner} spin={true} /></div>
          </div>
          :
          <div className="mx-auto w-full max-w-md">
            <div className="bg-white py-6 px-4 shadow rounded-lg">
              <form className="space-y-6" onSubmit={submit}>
                <div>
                  <label htmlFor="language" className="block text-sm font-medium text-gray-700">
                    Language
                  </label>
                  <select
                    value={language}
                    onChange={( e ) => setLanguage( e.target.value )}
                    id="language"
                    name="language"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-sky-900 focus:border-sky-900 sm:text-sm rounded-md"
                  >
                    <option value="nl-NL">Dutch</option>
                    <option value="en-US">English</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      value={password}
                      onChange={( e ) => setPassword( e.target.value )}
                      id="password"
                      name="password"
                      type="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-900 focus:border-sky-900 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-900 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    Transcribe
                  </button>
                </div>
              </form>
            </div>
          </div>
      }
    </>
  }

  return <div className="mx-auto w-full max-w-md">
    <div className="bg-white py-6 px-4 shadow rounded-lg">
      <div className="mb-6 text-sky-900 text-center text-5xl">
        {
        audioPlaying ?
          <button onClick={() => { audioObject.pause() } }><FontAwesomeIcon icon={faPause} /></button> :
          <button onClick={() => { audioObject.play() } }><FontAwesomeIcon icon={faPlay} /></button>
        }
      </div>

      <div>
        {result.map( ( item, i ) => <span key={i}>{item.type === "pronunciation" && i !== 0 ? " " : ""}<span className={`${item.isHighlighted ? "bg-yellow-200" : ""}`}>{item.content}</span></span> )}
      </div>
    </div>
  </div>
}

export default App
